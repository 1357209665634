<template>
  <div>
    <b-card>
      <ValidationObserver ref="horse" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="horse.horse.translations[0].name" :name="$t('horses.nameAr')" :label="$t('horses.nameAr')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="horse.horse.translations[1].name" :name="$t('horses.nameEn')" :label="$t('horses.nameEn')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="horse.horse.weight" :name="$t('horses.weight')" :label="$t('horses.weight')" :placeholder="$t('units.kg')" validate="required|decimal" :disabled="disableStatus" />
          </b-col>

          <b-col md="6">
            <main-select :options="owners" label="name" v-model.number="horse.horse.owner_id" :reduce="i => +i.id" :text="horse.horse.ownerName" :labelTitle="$t('owners.item')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
          <b-col md="6">
            <main-select :options="trainers" label="name" v-model.number="horse.horse.trainer_id" :reduce="i => +i.id" :text="horse.horse.trainerName" :labelTitle="$t('trainers.item')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
          <b-col md="6">
            <main-select :options="horseGenders" label="name" v-model.number="horse.horse.horse_sex_id" :reduce="i => +i.id" :text="horse.horse.horseSexName" :labelTitle="$t('horseGenders.item')" validate="required" :showAsInfo="disableStatus" />
          </b-col>
          <b-col md="12">
            <date-time-picker :enableTime="false" v-model="horse.horse.birth_date" :name="$t('global.birthDate')" :label="$t('global.birthDate')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>

    <b-card v-if="currentPage == itemPages.view">
      <participants :horseId="id" :titleStatus="true" />
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import horseServices from '../services/horses'
import ownersService from '../../owners/services/owners'
import trainersService from '../../trainers/services/trainers'
import horseGendersService from '../../horseGenders/services/horseGenders'
import Horse from '../models/Horse'
import Participants from '../../../events/participants/views/Participants.vue'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  components: { Participants },
  data () {
    return {
      horse: new Horse(),
      owners: [],
      trainers: [],
      horseGenders: []
    }
  },
  methods: {
    async create () {
      horseServices.create(this.horse.horse).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.horses' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      horseServices.update(this.id, this.horse.horse).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'contributor.horses' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      horseServices.findOne(this.id).then(response => {
        this.horse.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }

    if (!this.disableStatus) {
      ownersService.findAll().then((res) => {
        this.owners = res.data.data
      })
      trainersService.findAll().then((res) => {
        this.trainers = res.data.data
      })
      horseGendersService.findAll().then((res) => {
        this.horseGenders = res.data.data
      })
    }
  }
}
</script>
