import api from "@/axios";

export default {
  findAll() {
    return api().get("horse-sex");
  },
  findOne(id) {
    return api().get(`admin/horse-sex/${id}`);
  },
  create(data) {
    return api().post("admin/horse-sex/add", data);
  },
  update(id, data) {
    return api().patch(`admin/horse-sex/${id}`, data);
  },
  remove(id) {
    return api().delete(`horse-sex/${id}`);
  },
};
