export default class Horse {
  constructor() {
    this.setInitialValue();
  }

  setInitialValue() {
    this.horse = {
      birth_date: "",
      weight: "",
      owner_id: null,
      ownerName: "",
      trainer_id: null,
      trainerName: "",
      horse_sex_id: null,
      horseSexName: "",
      translations: [
        { id: null, name: "", lang: "ar" },
        { id: null, name: "", lang: "en" },
      ],
    };
  }

  fillData(data) {
    if (data) {
      const itemAr = data.translations?.find((o) => o.lang === "ar");
      const itemEn = data.translations?.find((o) => o.lang === "en");

      this.horse.birth_date = data.birth_date ? data.birth_date : "";
      this.horse.weight = data.weight ? data.weight : "";
      this.horse.owner_id = data.owner_id ? +data.owner_id : null;
      this.horse.ownerName = data.ownerName ? data.ownerName : "";
      this.horse.trainer_id = data.trainer_id ? +data.trainer_id : null;
      this.horse.trainerName = data.trainerName ? data.trainerName : "";
      this.horse.horse_sex_id = data.horse_sex_id ? +data.horse_sex_id : null;
      this.horse.horseSexName = data.horseSexName ? data.horseSexName : "";
      this.horse.translations[0].id = itemAr ? itemAr.id : null;
      this.horse.translations[0].name = itemAr ? itemAr.name : "";
      this.horse.translations[1].id = itemEn ? itemEn.id : null;
      this.horse.translations[1].name = itemEn ? itemEn.name : "";
    } else {
      this.setInitialValue();
    }
  }
}
